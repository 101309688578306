import Axios from "../config/axios";

export default {
  getAll() {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/field-visits")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/field-visits/" + id + "/show")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      Axios.delete("/admin/field-visits/" + id + '/delete')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  create(data) {
    return new Promise((resolve, reject) => {
      Axios.post("/admin/field-visits/create", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/field-visits/" + id + "/update", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
